import React, {useContext} from "react"
import { ContentText, ContentTextBold } from "../constants/MyFonts";
import {styled} from '@mui/material'

import SectionsTemplate from "./sections/sectionsTemplate";
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

function AboutMe(props) {
    const { 
        theme,
    } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 50, 
        objectFit: 'cover',
        borderRadius: 100,
        margin: 8
    })

    const ImageContainerAboutme = styled('img')({
        width: 350, 
        objectFit: 'cover',
        margin: 8
    })

    const content = <div style={{display: 'flex', flex: 1, flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} id='aboutme' >
    <ImageContainerAboutme src={'../images/imageaboutme.png'} />
    <ContentText style={{flex: 2, color: theme.text}}>
        <ContentTextBold style={{fontSize: 24, color: theme.text}}>
            About Me
        </ContentTextBold>
        {/* <ContentText style={{fontSize: 18, color: MyColors.primaryColors.main}}>
            With over 10 years experience in 
            I am confident that I can help you
            build a modern website tailored to your needs. Take a look at my pertfolio
            although not extensive given this business is quite new the quality speaks for itself.
        </ContentText> */}
        <ContentText style={{fontSize: 18, color: theme.highlightedText}}>
            I am an enthusiastic web/UI <b>designer</b> & <b>developer</b>, I love my work and give over 100% to every project I am a part of. 
            The end user is always at the heart of what I do. I have over 15 years of professional experience over 3 jobs. 
            Most recently I have been part of designing a scan and shop system with a supermarket. 
            I have also created a few of my own websites and apps which can be found below.
        </ContentText>
        <ContentText style={{fontSize: 18, color: theme.highlightedText}}>
            I have a bachelors in Computer Science and Masters in AI for games. I have skills for both UX design and software development.
        </ContentText>

    </ContentText>
    </div>

    return (
        <SectionsTemplate 
            title='' 
            content={content} 
            backgroundColor={theme.background} 
        />
    )
}

export default AboutMe