import React, {useState, useEffect, useContext} from "react"
import {Button, styled} from '@mui/material'
import {motion} from 'framer-motion'

import { activityData, mealsData, qnData } from "../data/projectsData";
import ProjectsData from "../data/projectsData";
import { AppContext } from "../store/context/app-context";
import { MyColors } from "../constants/MyColors";

function AppInfoTemplate(props) {

  const {
    theme
  } = useContext(AppContext);

  const open = props.open;

  const cardType = props.cardType;
  const [cardDetails, setCardDetails] = useState(false);
  
  const CardWrapper = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 200,
    margin: 16,
    paddingBottom: 16,
    backgroundColor: theme.background,
    // borderRadius: 8,
    // // borderWidth: 1,
    // // borderStyle: 'solid',
    // // borderColor: theme.border,
    // // ['@media (max-width:780px)']: {
    // //   width: '100%',
    // // },
    // boxShadow: `0px 0px 10px ${MyColors.primaryColors.main}`
  });

  const CardWrapperOpen = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // height: '100%',
    margin: 16,
    // paddingTop: 16,
    backgroundColor: theme.background,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.border,
    borderRadius: 8,
    ['@media (max-width:780px)']: {
      width: '100%',
    },
    height: '100%',
    overflowY: 'scroll',
  });
  
  const CardTopContent = styled('div')({
    display:'flex', 
    flexDirection: 'column', 
    flex: 1, 
    justifyContent: 'space-between', 
    alignItems: 'flex-start', 
    width: '100%',
  });

  const CardMainContent = {
    display:'flex', 
    flexDirection: 'column', 
    width: '100%', 
    alignItems: open? 'flex-start' : 'center',
    justifyContent: open? 'flex-start' : 'center',
  }
  
  const ImageContainer = styled('img')({
    width: '100%', 
    // padding: 16,
    height: 80, 
    objectFit: 'cover',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  })

  useEffect(() => {
    switch(cardType) {
      case 'meals':
        setCardDetails(mealsData);
        break;
      case 'activity':
        setCardDetails(activityData);
        break;
      case 'qn':
        setCardDetails(qnData);
        break;
    }
  }, [open])

  if(open) {
    return (
      <CardWrapperOpen>
        <motion.div 
          // layoutId={cardDetails.heading} 
          style ={CardMainContent}
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          transition={{ duration: 1 }}
        >
          <ProjectsData appName = {cardType} theme={theme} open ={open} />
        </motion.div>
      </CardWrapperOpen>
    )
  }
  else {
    return (
      <CardWrapper style={theme.cardStyle}>
          <CardTopContent>
              <ImageContainer src={cardDetails.banner} />
                <motion.div 
                  style ={CardMainContent}
                  // layoutId={cardDetails.heading}
                  initial={{ opacity: 0}}
                  animate={{ opacity: 1}}
                  transition={{ duration: 0.5 }} 
                >
                  <ProjectsData appName = {cardType} theme={theme} open ={open} />
              </motion.div>
          </CardTopContent>
      </CardWrapper>
    )
  }
}

export default AppInfoTemplate