import { SubHeadingText, HeadingTextBold, ContentText, ContentTextBold } from "../constants/MyFonts"
import AppInfoBanner from '../components/appInfoBanner';
import {styled} from '@mui/material';
import { AndroidRounded, Apple, MobileFriendly, DesktopMac } from '@mui/icons-material';
import { iconPrimarySmall, iconPrimarySmallDisabled } from "../constants/MyStyles";
import { MyColors } from "../constants/MyColors";

export const mealsData = {
    banner: '../images/projects/mealsApp/mealsBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/mealsApp/Homepageimage.png',
            text: ''
        },
        {
            page: 1,
            image: '../images/projects/mealsApp/AddMealImage.png',
            text: ''
        },
        {
            page: 2,
            image: '../images/projects/mealsApp/CalendarImage.png',
            text: ''
        },
        {
            page: 3,
            image: '../images/projects/mealsApp/ShoppingListImage.png',
            text: ''
        }
    ]
}

export const activityData = {
    banner: '../images/projects/activitiesApp/activityBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/activitiesApp/image1.png',
            text: ''
        },
    ]
}

export const qnData = {
    banner: '../images/projects/qn/qnBanner.png',
    pages: [
        {
            page: 0,
            image: '../images/projects/qn/image1.png',
            text: ''
        },
        {
            page: 1,
            image: '../images/projects/qn/image2.png',
            text: ''
        },
        {
            page: 2,
            image: '../images/projects/qn/image3.png',
            text: ''
        },
        // {
        //     page: 3,
        //     image: '../images/projects/qn/image4.png',
        //     text: ''
        // },
    ]
}

const ProjectsData = (props) => {
    const appName = props.appName;
    const theme = props.theme;
    const open = props.open;

    const SectionOneWrapper = styled('div')({
        display:'flex', 
        flexDirection: 'column', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        padding: 24,
        backgroundColor: theme.sectionBackgroundOne
      });

      const SectionTwoWrapper = styled('div')({
        display:'flex', 
        flexDirection: 'column', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        padding: 24,
        backgroundColor: theme.sectionTwoBackground
      });

      const SectionQNWrapper = styled('div')({
        display:'flex', 
        flexDirection: 'column', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        padding: 24,
        backgroundColor: MyColors.apps.qnBackground
      });

      const SectionATWrapper = styled('div')({
        display:'flex', 
        flexDirection: 'column', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        padding: 24,
        backgroundColor: MyColors.apps.activityBackground
      });

      const SectionMealsWrapper = styled('div')({
        display:'flex', 
        flexDirection: 'column', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        padding: 24,
        backgroundColor: MyColors.apps.mealsBackground
      });

      const IconRow = styled('div')({
        display:'flex', 
        flexDirection: 'row', 
        flex: 1, 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        width: '100%',
        backgroundColor: theme.sectionTwoBackground
      });

      const ImageStyle = styled('img')({
        // height: '50%', 
        width: '100%',
        marginBottom: 24,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: MyColors.colors.lightGrey
    })

    if(appName === 'meals') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Meal Book - Expert Recipes</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>Try this exciting new app to help you plan and organise your meals.</ContentText>
                    <IconRow>
                        <AndroidRounded sx={iconPrimarySmall} />
                        <Apple sx={iconPrimarySmall} />
                        <MobileFriendly sx={iconPrimarySmall} />
                        <DesktopMac sx={iconPrimarySmall} />
                    </IconRow>
                    
                </>
                :
                <>
                    <AppInfoBanner appName = 'mealsData' bkColor={MyColors.apps.mealsBackground} />

                    <SectionMealsWrapper>
                        <div style ={{width: '100%', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 24, paddingBottom: 24}}>
                            <HeadingTextBold style={{color: MyColors.colors.white}}>Meal Book - Expert Recipes</HeadingTextBold>
                            <SubHeadingText style={{color: MyColors.colors.white, textAlign: 'center'}}>This app helps you organise your meals</SubHeadingText>
                        </div>
                    </SectionMealsWrapper>

                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>Start your cooking journey today!</ContentText>
                        <ContentText style={{color: theme.text}}>
                            We have made it easy for you to add new and existing recipes and plan them into your calendar.
                            We all want a balanced diet to be healthier and to do that we need to organise our meals to make sure we are getting the nutrition we need throughout the week.
                            Meal Book makes it easy for you to save your favourite meals in one place and plan them into your calendar.
                        </ContentText>

                        <ContentTextBold style={{color: theme.text}}>ADD</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Add your favourite meals into the app manually or by using a link from supported websites.</ContentText>
                        <ContentTextBold style={{color: theme.text}}>PLAN</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Plan your favourite meals into your calendar so you don't have to think about what to have for dinner again.</ContentText>
                        <ContentTextBold style={{color: theme.text}}>SHOP</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Conveniently add any items you don't have into your shopping list with one click, all in the same app!</ContentText>
                        <ContentTextBold style={{color: theme.text}}>COOK</ContentTextBold>
                        <ContentText style={{color: theme.text}}>Finally cook your chosen meals and enjoy the health benefits!</ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.text}}>Design Process</SubHeadingText>
                        <ContentText style={{color: theme.text}}>---</ContentText>
                    </SectionOneWrapper>

                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Node.js, Express, React Native, CSS</ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.text}}>Available to Download</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                        <ContentText style={{color: theme.text}}>Comming soon on web</ContentText>
                    </SectionOneWrapper>
                </>
        )
    }
    else if(appName === 'activity') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Activity Tracker</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>End the boredom and enjoy a range of different suggestions to fill your time.</ContentText>

                    <IconRow>
                        <AndroidRounded sx={iconPrimarySmallDisabled} />
                        <Apple sx={iconPrimarySmallDisabled} />
                        <MobileFriendly sx={iconPrimarySmallDisabled} />
                        <DesktopMac sx={iconPrimarySmallDisabled} />
                    </IconRow>
                </>
                :
                <>
                    <AppInfoBanner appName = 'activityData' bkColor={MyColors.apps.activityBackground} />

                    <SectionATWrapper>
                        <div style ={{width: '100%', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 24, paddingBottom: 24}}>
                            <HeadingTextBold style={{color: MyColors.colors.white}}>Activity Tracker</HeadingTextBold>
                            <SubHeadingText style={{color: MyColors.colors.white, textAlign: 'center'}}>Find your next time filler with a shake of your phone</SubHeadingText>
                        </div>
                    </SectionATWrapper>

                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>Fill your day with fun activities.</ContentText>
                        <ContentText style={{color: theme.text}}>
                            
                        </ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.text}}>Design Process</SubHeadingText>
                        <ContentText style={{color: theme.text}}>---</ContentText>
                    </SectionOneWrapper>

                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>React Native, CSS</ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.text}}>Available to Download Soon</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                    </SectionOneWrapper>
                </>
        )
    }
    if(appName === 'qn') {
        return (
            !open?
                <>
                    <HeadingTextBold style={{color: theme.text}}>Quran Notes (QN)</HeadingTextBold>
                    <ContentText style={{color: theme.text}}>Elevate you Quran reading and make notes using this easy to use app.</ContentText>
                    <IconRow>
                        <AndroidRounded sx={iconPrimarySmallDisabled} />
                        <Apple sx={iconPrimarySmallDisabled} />
                        <MobileFriendly sx={iconPrimarySmallDisabled} />
                        <DesktopMac sx={iconPrimarySmallDisabled} />
                    </IconRow>
                </>
                :
                <>
                    <AppInfoBanner appName = 'qnData' bkColor={MyColors.apps.qnBackground} />

                    <SectionQNWrapper>
                        <div style ={{width: '100%', display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingTop: 24, paddingBottom: 24, backgroundColor: MyColors.apps.qnBackground}}>
                            <HeadingTextBold style={{color: MyColors.colors.white}}>Quran Notes</HeadingTextBold>
                            <SubHeadingText style={{color: MyColors.colors.white, textAlign: 'center'}}>Elevate you Quran reading and make notes using this easy to use app</SubHeadingText>
                        </div>
                    </SectionQNWrapper>

                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>App Information</SubHeadingText>
                        
                        <ContentText style={{color: theme.text}}>
                            The purpose of this app is to give you a pltform where you can learn the Quran while you read. It is a place to organise
                            all your quran notes into Surahs and Ayahs so you can refer back to them easily.
                        </ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>Design Process</SubHeadingText>
                            <div>
                                <ContentText style={{color: theme.text, fontWeight: 'bold'}}>
                                    Requirements Gathering
                                </ContentText>
                                <ContentText style={{color: theme.text}}>
                                    The design of the app started with gathering an in depth understanding of what users would want from the app.
                                </ContentText>
                            </div>
                            <div>
                                <ContentText style={{color: theme.text, fontWeight: 'bold'}}>
                                    Wireframes & Prototypes
                                </ContentText>
                                <ContentText style={{color: theme.text}}>
                                    The next step was to get together low and high fidelity wireframes to construct how the app could be used in the easiest way.
                                </ContentText>
                            </div>

                            <div>
                                <ContentText style={{color: theme.text, fontWeight: 'bold'}}>
                                    The Dev
                                </ContentText>
                                <ContentText style={{color: theme.text}}>
                                    I then started developing the app using react native and creating resusable components along theway to 
                                    help future app maintenance.
                                </ContentText>
                            </div>
                            <div>
                                <ContentText style={{color: theme.text, fontWeight: 'bold'}}>
                                    Test & Release
                                </ContentText>
                                <ContentText style={{color: theme.text}}>
                                    Finally the app was tested thoroughly and released on android and apple
                                </ContentText>
                            </div>
                            
                            <ImageStyle src="../images/projects/qn/wireframes/1.png" />
                            <ImageStyle src="../images/projects/qn/wireframes/2.png" />
                    </SectionOneWrapper>


                    <SectionTwoWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>Technologies Used</SubHeadingText>
                        <ContentText style={{color: theme.text}}>React Native, Redux, HTML, CSS</ContentText>
                    </SectionTwoWrapper>

                    <SectionOneWrapper>
                        <SubHeadingText style={{color: theme.highlightedText}}>Available to Download</SubHeadingText>
                        <ContentText style={{color: theme.text}}>Apple Store, Google Play store</ContentText>
                        <ContentText style={{color: theme.text}}>Comming soon on web</ContentText>
                    </SectionOneWrapper>
                </>
        )
    }
}

export default ProjectsData