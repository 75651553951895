export const MyColors = {
    colors: {
        black: '#222222',
        lightGrey: '#D2D2D2',
        white: '#FFFFFF',
        charcoal: '#3B3B3B'
    },
    primaryColors: {
        main: '#062F4E',
        main2: '#032136',
        secondary: '#FFE381',
        black: '#222222',
        background: '#FFF8E0'
    },
    secondaryColors: {
        grey: '#E4ECFF'
    },
    textColors: {
        black: '#222222',
        pink: '#FF87AB',
        white: '#FFFFFF',
        linkHeader: '#FFFFFF',
        linkContent: '#016F0C',
        menu: '#031929'
    },
    apps: {
        qnBackground: '#2E2E2E',
        mealsBackground: '#8D1900',
        activityBackground: '#DC1438'
    }
}

export const LightTheme = {
    label: 'light',
    background: MyColors.colors.white,
    sectionBackgroundOne: MyColors.secondaryColors.grey,
    sectionBackgroundTwo: MyColors.secondaryColors.grey,
    text: MyColors.primaryColors.black,
    highlightedText: MyColors.textColors.main,
    headingText: MyColors.primaryColors.main2,
    link: MyColors.primaryColors.main,
    border: MyColors.primaryColors.main,
    input: MyColors.colors.white,
    cardStyle: {
        borderRadius: 2,
        // boxShadow: `0px 0px 10px ${MyColors.primaryColors.main}`
    },
    dotActive: MyColors.primaryColors.main,
    dot: '#D5D5D5'
}

export const DarkTheme = {
    label: 'dark',
    background: MyColors.primaryColors.main2,
    sectionBackgroundOne: MyColors.primaryColors.main,
    sectionBackgroundTwo: MyColors.primaryColors.main,
    text: MyColors.colors.white,
    highlightedText: MyColors.primaryColors.secondary,
    headingText: MyColors.primaryColors.secondary,
    link: MyColors.primaryColors.secondary,
    border: MyColors.colors.white,
    input: MyColors.colors.white,
    cardStyle: {
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: MyColors.colors.white,
    },
    dotActive: MyColors.colors.white,
    dot: '#838282'
}