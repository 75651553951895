import React, {useContext} from "react"
import { ContentText, ContentTextBold } from "../constants/MyFonts";
import {styled} from '@mui/material'

import SectionsTemplate from "./sections/sectionsTemplate";
import { MyColors } from "../constants/MyColors";
import { AppContext } from "../store/context/app-context";

function SkillsAndExp(props) {
    const { 
        theme,
    } = useContext(AppContext);

    const ImageContainer = styled('img')({
        // width: 50, 
        height: 50, 
        objectFit: 'cover',
        borderRadius: 100,
        margin: 8
    })

    const content = <div id='skills' >
        <ContentText style={{color: theme.text}}>
            <ContentText style={{color: theme.text}}>
                Since graduating university in 2009 I have gained over 15 years experience in 
                <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> web developement </text>
                and 
                <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> UX/UI design. </text> 
            </ContentText>
            <ContentText style={{color: theme.text}}>
                After I graduated with a BSc in Computer Science I just wasn't done learning so I decided to do a MSc in AI for Games.
                The passion to keep learning has stayed with me, I am always doing some sort of course online to further my skills. 
                I have recently learnt React Native and developed a few apps in my spare time.
            </ContentText>
            <ContentText style={{color: theme.text}}>
                In <b>personal projects</b> I have developed apps using React Native, CSS3 and HTML5. I have also worked on websites creating backend APIs usinf Node.js, Express and Mongo DB. I <b>designed, developed</b> and <b>tested</b> my own applications.
            </ContentText>
            <ContentText style={{color: theme.text}}>
                In <b>commercial/workplace projects</b> I have done some front end work on apps using <b>React Native, CSS3</b> and <b>HTML5</b>. However I have 
                spend a lot of time building my <b>UX/UI skills</b>. My passion for making functional applications usable led me to take on the UX role in my previous
                workplace where I was working in an <b>agile</b> environment gathering <b>requirements</b>, building <b>high/low fidelity prototypes</b> and mockups and working closely 
                with developers to ensure the designs were implemented correctly.
            </ContentText>
            <ContentText style={{color: theme.text}}>
                I have also been in charge of creating and mainataining a design library of brand styled and components.
            </ContentText>
            <ContentText style={{color: theme.text}}>
                My Skills include by are not limited to:
            </ContentText>
            <ContentText style={{color: theme.highlightedText}}>
               <b>Front End Dev: </b>HTML, CSS, React Native, React, PHP, Node.js, Express
            </ContentText><ContentText style={{color: theme.highlightedText}}>
               <b>Databases: </b>MongoDB, MYSQL
            </ContentText>
            <ContentText style={{color: theme.highlightedText}}>
               <b>UX/UI: </b>Figma, Sketch, Framer, InVision, Axure RP
            </ContentText>

        </ContentText>
    {/* <ContentText style={{color: theme.text}}> */}
        {/* <ContentText style={{color: theme.text}}>
            With over 10 years experience in 
            <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> web design </text>
            and 
            <text style={{color: theme.highlightedText, fontWeight: 'bold'}}> UX </text> 
            I am confident that I can help you
            build a modern website tailored to your needs. Take a look at my pertfolio
            although not extensive given this business is quite new the quality speaks for itself.
        </ContentText>

        <ContentTextBold style={{color: theme.text}}>
            A good website starts with a good design that keeps the user journey in mind.
        </ContentTextBold>
        
        <ContentText style={{color: theme.text}}>
            Having worked as a UX designer for a number of years I can help you understand
            your users so you can build a website tailored to their need 
            making your business a success.
        </ContentText>

        <ContentText style={{color: theme.text}}>
            My skills include but are not limited to:
        </ContentText>

        <ContentTextBold style={{color: theme.text}}>
            React (web) & React Native (Mobile Apps), Backend API using nodeJS, 
            Database setup using MongoDB
        </ContentTextBold> */}
        
    {/* </ContentText> */}
    </div>

    return (
        <SectionsTemplate 
            title='Expertise' 
            content={content} 
            backgroundColor={theme.background} 
        />
    )
}

export default SkillsAndExp