import {styled, Toolbar, Typography} from '@mui/material'
import {Link} from 'react-router-dom'
import { MyColors } from './MyColors'

export const PrimaryScreenStyled = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 24,
  })

  export const ScreenStyled = styled('div')({
    display:'flex', 
    flex: 1, 
    flexDirection: 'column', 
    color: MyColors.colors.white,
    paddingBottom: 44,
  })

  export const Content = styled('div')({
    display: 'flex',
    // flex:1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height:'60%',
    // flexGrow: 1,
    fontSize: 18,
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    color: MyColors.textColors.primary,
    // ['@media (max-width:780px)'] :{
    //     paddingBottom: "1rem"
    // },
  })

export const iconPrimarySmall = {
  fontSize: 20, 
  color: MyColors.primaryColors.main
}

export const iconPrimarySmallDisabled = {
  fontSize: 20, 
  color: MyColors.colors.lightGrey
}

export const iconPrimary = {
  fontSize: 32, 
  color: MyColors.primaryColors.main
}

export const iconPrimaryLarge = {
  fontSize: 62, 
  color: MyColors.primaryColors.main
}

export const iconWhite = {
  fontSize: 32, 
  color: MyColors.colors.white
}

export const iconWhiteLarge = {
  fontSize: 62, 
  color: MyColors.colors.white
}

export const iconSecondary = {
  fontSize: 32, 
  color: MyColors.primaryColors.secondary
}